// @flow
import * as React from 'react'
import { ImageBlock, useBlock } from '@shift-marketing/shift-one-website-editor'

import FooterBar from 'components/FooterBar'

import styles from './styles.module.scss'

import propertiesSchema from './properties.schema.json'

export default function Footer () {
  const { properties, ref } = useBlock('footer', propertiesSchema)

    return (
        <div
            className={styles.wrapper}
            style={{
                backgroundColor: properties.backgroundColor,
                backgroundBlendMode: properties.backgroundBlendMode,
            }}
            ref={ref}
        >
            <div className={styles.container}>
                <div className={styles.logoContainer}>
                    <ImageBlock
                        blockId="footer.logo"
                        defaultImage="$.logo.inverted"
                        resize="both"
                        maxWidth="170px"
                        title="Footer logo"
                    />
                </div>
            </div>

            <FooterBar/>
        </div>
    )
}

