import React from 'react'
import {
    BackgroundImageBlock,
    TextBlock,
} from '@shift-marketing/shift-one-website-editor'

import { createClickActivity } from 'Actions'
import { Store } from 'Store'
import useTheme from 'hook/useTheme'

import VideoPlayButton from 'components/VideoPlayButton'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

const DEFAULT_HEADER_MESSAGE = `
    <h2>Thank you, {firstName}. Your donation will help make a difference in our students lives.</h2>
`

export default function HeaderImage (props: { headingColor: string }) {
    const theme = useTheme(supportedThemes)

    const { state } = React.useContext(Store)

    if (!theme) return null

    return (
        <div className={styles.container}>
            <BackgroundImageBlock
                className={styles.bgImg}
                defaultPositionX={50}
                defaultPositionY={'0'}
                blockId="landing.header.backgroundImage"
                defaultImage={theme.image}
                title="Header image"
            />

            <div className={styles.content}>
                <TextBlock
                    className={styles.headerMessage}
                    style={{
                        '--heading-color': props.headingColor,
                    }}
                    blockId="masthead.message"
                    values={{ ...state.lead.fields }}
                    valuesSchema={state.campaign.mergeTags}
                    locale={state.locale}
                    defaultContent={DEFAULT_HEADER_MESSAGE}
                    onAnchorClick={createClickActivity}
                />

                <VideoPlayButton/>
            </div>
        </div>
    )
}
